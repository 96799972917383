import React, { useMemo, useState } from 'react';

import useModal from 'shared/utils/hooks/useModal';

import { ContactUsInterface, IContactUsSelected } from 'interfaces/contactUs';

import { contactUsService } from 'shared/services/contactUs';

import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import AlertTemplate from 'components/templates/Alerts/Alert';

import {
  Container,
  ContainerInputs,
  Form,
  InputSubmit,
  LeftContent,
} from './style';

const ContactUs = () => {
  const { isOpen: isOpenAlertGood, toggle: toggleAlertGood } = useModal();
  const { isOpen: isOpenAlertFail, toggle: toggleAlertFail } = useModal();

  const [isHideHints, setIsHideHints] = useState(true);

  const [contactUs, setContactUs] = useState<ContactUsInterface>({
    organization_name: '',
    location: '',
    contact_name: '',
    contact_email: '',
    organization_contact_or_phone_number: '',
    fax: '',
    upin_or_organization_id: '',
    number_of_healthcare_workers_that_need_sign_ins: '',
  });

  const [inputFocus, setInputFocus] = useState<IContactUsSelected>({
    contact_email: false,
    contact_name: false,
    fax: false,
    location: false,
    number_of_healthcare_workers_that_need_sign_ins: false,
    organization_contact_or_phone_number: false,
    organization_name: false,
    upin_or_organization_id: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setContactUs({ ...contactUs, [name]: value });
  };

  const classByRequiredInputs = useMemo(
    () => (isHideHints ? 'hide-hints' : ''),
    [isHideHints]
  );

  const handleInvalidForm = () => setIsHideHints(false);

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name;

    setInputFocus({
      ...inputFocus,
      [name]: true,
    });
  };

  const handleBlurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name;

    setInputFocus({
      ...inputFocus,
      [name]: false,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await contactUsService.send_email(contactUs);
      toggleAlertGood();
    } catch (error) {
      toggleAlertFail();
    }
  };

  return (
    <Container id="contact-us" className="contact_us">
      <Text variant="ARIAL_25_700">FOR HEALTHCARE ORGANIZATIONS ONLY</Text>
      <Text variant="LATO_22_400" color="gray">
        Complete the form below to join the NoyaView Platform
      </Text>
      <Form onInvalid={handleInvalidForm} onSubmit={handleSubmit}>
        <ContainerInputs>
          <Input
            type="text"
            className={classByRequiredInputs}
            placeholder={
              !inputFocus.organization_name ? 'Organization Name *' : ''
            }
            required
            name="organization_name"
            value={contactUs.organization_name}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
          <Input
            type="text"
            placeholder={!inputFocus.location ? 'Location/City' : ''}
            name="location"
            value={contactUs.location}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
          <Input
            type="text"
            className={classByRequiredInputs}
            placeholder={!inputFocus.contact_name ? 'Contact Name *' : ''}
            required
            name="contact_name"
            value={contactUs.contact_name}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
          <Input
            type="text"
            placeholder={!inputFocus.contact_email ? 'Contact Email' : ''}
            name="contact_email"
            value={contactUs.contact_email}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
          <Input
            type="text"
            className={classByRequiredInputs}
            placeholder={
              !inputFocus.organization_contact_or_phone_number
                ? 'Organization Contact Telephone Number *'
                : ''
            }
            required
            name="organization_contact_or_phone_number"
            value={contactUs.organization_contact_or_phone_number}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
          <Input
            type="text"
            placeholder={!inputFocus.fax ? 'Organization Fax Number' : ''}
            name="fax"
            value={contactUs.fax}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
          <Input
            type="text"
            placeholder={
              !inputFocus.upin_or_organization_id
                ? 'Healthcare Entity UPIN/Organization ID'
                : ''
            }
            name="upin_or_organization_id"
            value={contactUs.upin_or_organization_id}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
          <Input
            type="text"
            placeholder={
              !inputFocus.number_of_healthcare_workers_that_need_sign_ins
                ? 'No. of Healthcare worker that need Sign-Ins'
                : ''
            }
            name="number_of_healthcare_workers_that_need_sign_ins"
            value={contactUs.number_of_healthcare_workers_that_need_sign_ins}
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlurInput}
          />
        </ContainerInputs>
        <LeftContent>
          <Text color="green">* Required</Text>
        </LeftContent>
        <InputSubmit type="submit" value="Submit" />
      </Form>

      {isOpenAlertGood && (
        <AlertTemplate
          msg="Thank you we have received your message.
                We'll be in touch soon!"
          onHelp={toggleAlertGood}
          typeAlert="good"
        />
      )}

      {isOpenAlertFail && (
        <AlertTemplate
          msg="Sorry something went wrong :(
                Please try again."
          onHelp={toggleAlertFail}
          typeAlert="fail"
        />
      )}
    </Container>
  );
};

export default ContactUs;
