import styled from 'styled-components';

import theme from 'theme';

import Text from 'components/atoms/Text';
import Button from 'components/atoms/Button';
import TitleComponent from 'components/atoms/Title';

export const HeaderStyle = styled.div`
  &.header__home {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh);

    ${theme.mediaQueries.xSmallScreen} {
      justify-content: flex-start;
    }
  }
`;

export const BGStyle = styled.div`
  width: 100%;
  height: 100%;
  transition: all 350ms;
  position: relative;
  background-image: url('/static/header-image.png');
  background-repeat: no-repeat;
  background-size: cover;
`;

export const WrapperStyle = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.blackOpacity(0.35)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 10vw 0;
`;

export const TitleStyle = styled(TitleComponent)`
  width: 100%;
  margin-bottom: 0;
  font-size: 30px;
  text-align: center;
  line-height: 50px;

  ${theme.mediaQueries.smallScreen} {
    width: 100%;
    font-size: 39px;
    line-height: 50px;
    text-align: center;
    margin-top: 0;
  }

  ${theme.mediaQueries.size929} {
    width: 70%;
    font-size: 45px;
    line-height: 58px;
    text-align: center;
    margin-top: 0;
  }

  ${theme.mediaQueries.largeScreen} {
    width: 45%;
    font-size: 45px;
    line-height: 58px;
    text-align: center;
    margin-top: 0;
    margin-left: 10%;
  }

  ${theme.mediaQueries.xLargeScreen} {
    width: 45%;
    font-size: 45px;
    line-height: 58px;
    text-align: center;
    margin-top: 0;
  }

  ${theme.mediaQueries.extraBigSize} {
    width: 45%;
    font-size: 55px;
    line-height: 65px;
    text-align: center;
    margin-top: 10%;
  }
`;

export const LoginRedirectStyle = styled.a`
  cursor: pointer;
`;

export const ButtonHeaderStyle = styled(Button)`
  position: absolute;
  height: 50px;
  width: 170px;
  justify-content: center;
  align-items: center;
  top: 15%;
  right: 10vw;

  ${theme.mediaQueries.smallScreen} {
    top: 20%;
    height: 66px;
    width: 237px;
  }

  ${theme.mediaQueries.surface7pro} {
    height: 50px;
    width: 200px;
  }

  ${theme.mediaQueries.size929} {
    height: 70px;
    width: 220px;
  }

  ${theme.mediaQueries.extraBigSize} {
    height: 70px;
    width: 280px;
  }
`;

export const TextButtonStyle = styled(Text)`
  font-size: 19.5px;

  ${theme.mediaQueries.smallScreen} {
    font-size: 29.5px;
  }

  ${theme.mediaQueries.extraBigSize} {
    font-size: 39.5px;
  }
`;
