import React from 'react';

// styles
import { ItemStyle, TextStyle, AncleStyle } from './style';

interface IOptionProps {
  html?: string;
  children?: React.ReactNode;
  className?: string;
  redirect?: string;
  option?: () => void;
}

const ItemOption = ({
  redirect = '',
  children,
  html,
  className = '',
}: IOptionProps) => {
  if (html) {
    return (
      <ItemStyle className={`menu__option--menu ${className}`}>
        <a href={redirect}>
          <span
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </a>
      </ItemStyle>
    );
  }

  return (
    <ItemStyle className={`menu__option--menu ${className}`}>
      <AncleStyle href={redirect}>
        <TextStyle color="white" variant="HELVETICA_16_700">
          {children}
        </TextStyle>
      </AncleStyle>
    </ItemStyle>
  );
};

export default ItemOption;
