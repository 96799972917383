import colors from './colors';
import fontsFamily from './fonts';
import breakpoints from './breakpoints';

const mediaQueries = {
  // auxiliar media queries
  xSmallAndHeightScreen: `@media screen and (max-width: ${breakpoints.sm}px) and (min-height: 500px) and (max-height: 600px)`,
  xxSmallScreen: `@media screen and (max-width: ${breakpoints.xxsm}px)`,
  surface7pro: `@media screen and (min-width: 912px and max-width: 912px)`,
  extraBigSize: `@media screen and (min-width: 1850px)`,
  size929: `@media screen and (min-width: 929px)`,
  size350Font: `@media screen and (max-width: 350px)`,
  xxSmallScreenToMax: `@media screen and (min-width: ${breakpoints.xxsm}px)`,
  screenTo660: `@media screen and (min-width: 660px)`,
  screenTo920: `@media screen and (min-width: 920px)`,
  screenTo1800: `@media screen and (min-width: 1800px)`,
  screenTo2250: `@media screen and (min-width: 2250px) and (min-height: 1500px)`,

  // Reference type media queries to First Mobile
  xSmallScreen: `@media screen and (min-width: ${breakpoints.xsm}px)`,
  smallScreen: `@media screen and (min-width: ${breakpoints.sm}px)`,
  mediumScreen: `@media screen and (min-width: ${breakpoints.md}px)`,
  largeScreen: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xLargeScreen: `@media screen and (min-width: ${breakpoints.xlg}px)`,
};

const theme = {
  colors,
  fontsFamily,
  breakpoints,
  mediaQueries,
};

export default theme;
