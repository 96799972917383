import styled, { css } from 'styled-components';

import theme from 'theme';

import { cssTextVariant } from 'components/atoms/Title/style';

const { colors } = theme;

export type VariantButton = 'FULL_GREEN' | 'OUTLINE_WHITE';

export const cssButtonVariant = {
  FULL_GREEN: css`
    width: auto;
    cursor: pointer;
    background: ${colors.green};
    box-shadow: 0px 2px 6px ${colors.whiteOpacity(0.1)};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    gap: 10px;

    &: hover {
      background: ${colors.white};
      border: solid 1px ${colors.green};

      p {
        color: ${colors.green} !important;
      }
    }
  `,

  OUTLINE_WHITE: css`
    width: auto;
    cursor: pointer;
    color: ${colors.green};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    gap: 10px;

    &: hover {
      border-bottom: solid 1px ${colors.green};
    }
  `,
};

export type IBorderType = 'default' | 'NO_BORDER';

interface IButtonProps {
  $variant: VariantButton;
  $isBorder: IBorderType;
}

export const ButtonStyle = styled.span<IButtonProps>`
  ${cssTextVariant.HELVETICA_20_700}
  ${({ $variant }) => cssButtonVariant[$variant]}
  ${({ $isBorder }) => $isBorder === 'default' && `border-radius: 44px;`}
`;
