const blackOpacity = (percent: number) => `rgba(0, 0, 0, ${percent})`;

const whiteOpacity = (percent: number) => `rgba(255, 255, 255, ${percent})`;

const colorsPalette = {
  white: '#FFF',
  whiteInput: '#F1F5FF',
  black: '#000',
  blackInput: '#212529',
  blueIntense: '#030639',
  green: '#5CB770',
  red: '#F50000',
  background: '#F5F5F5',
  blackOpacity,
  whiteOpacity,
  gray: '#666666',
  blackInputPlaceholder: '#212529',
};

export type TypesColors =
  | 'black'
  | 'white'
  | 'blueIntense'
  | 'green'
  | 'red'
  | 'gray'
  | 'blackInputPlaceholder';

export default colorsPalette;
