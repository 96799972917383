import Text from 'components/atoms/Text';
import styled from 'styled-components';
import theme from 'theme';

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  align-items: center;
  ${theme.mediaQueries.mediumScreen} {
    direction: ltr;
    align-items: start;
  }
`;

export const Title = styled(Text)`
  text-transform: uppercase;
  padding-bottom: 7.3px;
  border-bottom: 1px solid ${theme.colors.green};
  width: max-content;
  text-align: center;
`;
