import React from 'react';

import Navbar from 'components/organisms/Navbar';
import HeaderHome from 'components/organisms/HeaderHome';
import ContactUs from 'components/organisms/ContactUs';
import Information from 'components/organisms/Information';
import WrapperPagesContent from 'components/templates/WrapperPagesContent';

import { HomeStyle } from './style';

function Home() {
  return (
    <HomeStyle>
      <WrapperPagesContent isFooter>
        <Navbar />
        <HeaderHome />
        <Information />
        <ContactUs />
      </WrapperPagesContent>
    </HomeStyle>
  );
}

export default Home;
