import CONSTANTS from 'CONSTANTS';

import Axios from 'axios';

import { ContactUsInterface } from 'interfaces/contactUs';

export const contactUsService = {
  send_email: async (info: ContactUsInterface) => {
    const response = await Axios.post(
      `${CONSTANTS.api}/landing/contact-us`,
      info
    );

    return response;
  },
};
