import styled from 'styled-components';

import theme from 'theme';

import Text from 'components/atoms/Text';

const { colors } = theme;

export const ModalStyle = styled.div`
  transition: all 350ms;

  &.alert {
    width: 100%;
    display: flex;
    padding: 10px 5vw;
    align-items: center;
    justify-content: center;

    ${theme.mediaQueries.mediumScreen} {
    }
  }
`;

type ColorsOptions = 'red' | 'green';

interface ICloseProps {
  $variant: ColorsOptions;
}

export const StyleClose = styled.span<ICloseProps>`
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  border: solid 1px
    ${({ $variant }) =>
      $variant === 'green' ? `${colors.green}` : `${colors.red}`};
  position: absolute;
  border-radius: 75px;
  align-items: center;
  transition: all 350ms;
  justify-content: center;
  color: ${({ $variant }) =>
    $variant === 'green' ? `${colors.green}` : `${colors.red}`};
`;

export const StyleContent = styled.div`
  width: 80vw;
  padding: 10vh 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${theme.mediaQueries.mediumScreen} {
    width: 40vw;
  }
`;

export const StyleImg = styled.img`
  margin: 40px auto;
`;

export const StyleText = styled(Text)``;
