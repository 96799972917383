import styled from 'styled-components';

import theme from 'theme';

import TitleComponent from 'components/atoms/Title';
import { InformativeCard } from 'components/molecules/Cards';

const { colors, mediaQueries } = theme;

export const SectionStyle = styled.div`
  &.information__home {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // height: calc(100vh);
    background-color: ${colors.blueIntense};

    ${mediaQueries.xSmallScreen} {
      justify-content: flex-start;
    }
  }
`;

export const BGStyle = styled.section`
  width: 100%;
  transition: all 350ms;
  position: relative;
  padding: 2.5rem;
`;

export const WrapperStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px 15px;
  flex-wrap: wrap;
  margin: 10vh auto;

  ${mediaQueries.smallScreen} {
    gap: 25px 20px;
  }

  ${mediaQueries.mediumScreen} {
    gap: 50px 30px;
    width: 65%;
  }

  ${mediaQueries.largeScreen} {
    gap: 50px 30px;
    width: 75%;
  }

  ${mediaQueries.xLargeScreen} {
    gap: 50px 30px;
    width: 75%;
  }
`;

export const TitleStyle = styled(TitleComponent)`
  width: 100%;
  margin-bottom: 0;
  font-size: 20px;
  text-align: center;
  line-height: 36px;

  ${mediaQueries.smallScreen} {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    font-size: 45px;
    line-height: 60px;
    margin-top: 5vh;
  }

  ${mediaQueries.screenTo920} {
    font-size: 45px;
    text-align: center;
    line-height: 60px;
    margin-top: 5vh;
  }

  ${mediaQueries.mediumScreen} {
    text-align: center;
    width: 60%;
    margin: 0 auto;
    font-size: 48px;
    line-height: 60px;
    margin-top: 5vh;
  }

  ${mediaQueries.largeScreen} {
    text-align: center;
    width: 60%;
    margin: 0 auto;
    font-size: 40px;
    line-height: 60px;
    margin-top: 5vh;
  }
`;

export const CardStyle = styled(InformativeCard)`
  width: calc(50% - 10px);
  height: 72vw;

  ${mediaQueries.size350Font} {
    font-size: 100vw;
  }

  ${mediaQueries.xxSmallScreen} {
    font-size: 100vw;
  }

  ${mediaQueries.smallScreen} {
    width: calc(50% - 20px);
    height: 70vw;
  }

  ${mediaQueries.screenTo920} {
    width: calc(50% - 20px);
    height: 75vw;
  }

  ${mediaQueries.mediumScreen} {
    width: calc(50% - 20px);
    height: 62vw;
  }

  ${mediaQueries.largeScreen} {
    width: calc(50% - 20px);
    height: 62vw;
  }

  ${mediaQueries.largeScreen} {
    width: calc(32.3% - 30px);
    height: 35vw;
  }

  ${mediaQueries.xLargeScreen} {
    width: calc(32.3% - 30px);
    height: 35vw;
  }

  ${mediaQueries.screenTo1800} {
    width: calc(32.3% - 30px);
    height: 35vw;
  }

  ${mediaQueries.screenTo2250} {
    width: calc(32.3% - 30px);
    height: 32vw;
    padding: 5vh 2.5vw;
  }
`;
