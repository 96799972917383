import { useEffect, useState } from 'react';

export default function useTopState(): boolean {
  const [isTop, setIsTop] = useState<boolean>(false);

  useEffect(() => {
    window.onscroll = () =>
      window.pageYOffset === 0 ? setIsTop(true) : setIsTop(false);

    return () => {
      window.onscroll = null;
    };
  }, []);

  return isTop;
}
