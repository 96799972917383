const breakpoints = {
  xxsm: 361,
  xsm: 525,
  sm: 768,
  md: 992,
  lg: 1200,
  xlg: 1400,
};

export default breakpoints;
