import styled from 'styled-components';
import theme from 'theme';

export const InputStyle = styled.input`
  &::placeholder {
    color: ${theme.colors.gray};
    font-family: ${theme.fontsFamily.helvetica};
    font-size: 16px;
    line-height: 30px;
  }
  height: 41px;
  border-radius: 5px;
  border: 0.833333px solid #ced4da;
  outline: none;
  padding: 2px 15px;
  &:not(.hide-hints):invalid {
    border-color: red;
  }
  &:not(:placeholder-shown) {
    color: ${theme.colors.blackInput};
    background: ${theme.colors.whiteInput};
    padding: 2px 15px;
  }
`;
