import React from 'react';
import Lottie from 'components/atoms/Lottie';

// styles
import { ModalStyle, StyleClose, StyleContent, StyleText } from './style';

interface ITextProps {
  msg?: string;
  typeAlert?: string;
  className?: string;
  onHelp?: () => void;
}

const lottieOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const AlertTemplate = ({
  className = '',
  msg,
  typeAlert,
  onHelp,
}: ITextProps) => {
  return (
    <>
      <ModalStyle className={`alert  ${className}`}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*body*/}
              <StyleContent className="relative p-6 flex-auto">
                <StyleClose
                  $variant={typeAlert === 'good' ? 'green' : 'red'}
                  onClick={onHelp}
                >
                  x
                </StyleClose>
                {typeAlert === 'good' && (
                  <Lottie
                    options={{ ...lottieOptions, animationData: 'check' }}
                    width={200}
                  />
                )}
                {typeAlert === 'fail' && (
                  <Lottie
                    options={{ ...lottieOptions, animationData: 'fail' }}
                    width={200}
                  />
                )}
                <StyleText
                  color={typeAlert === 'good' ? 'green' : 'red'}
                  variant="HELVETICA_14_400"
                  className="my-4 text-slate-500 text-lg leading-relaxed"
                >
                  {msg}
                </StyleText>
              </StyleContent>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </ModalStyle>
    </>
  );
};

export default AlertTemplate;
