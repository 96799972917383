import React, { useId } from 'react';
import FooterSectionItem from '../FooterSectionItem';
import { Container, Title } from './style';

interface Items {
  path?: string;
  label: string;
}

interface Props {
  title: string;
  items: Items[];
}

const FooterSection = ({ title, items }: Props) => {
  const id = useId();

  return (
    <Container>
      <Title variant="HELVETICA_20_700" color="white">
        {title}
      </Title>
      {items.map((item, i) => (
        <FooterSectionItem
          title={item.label}
          path={item.path}
          key={`footer_section_${id}_${i}`}
        />
      ))}
    </Container>
  );
};

export default FooterSection;
