import styled from 'styled-components';
import theme from 'theme';

export const FooterStyled = styled.footer`
  &.footer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

export const InformationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const BlurContainer = styled.div`
  // filter: blur(2.5px);
  background: #0c1a3c;
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
export const NoBlurContainer = styled.div`
  padding: 50px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${theme.mediaQueries.mediumScreen} {
    flex-direction: row-reverse;
  }
`;

export const Information = styled.div`
  margin-top: 56px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${theme.mediaQueries.mediumScreen} {
    margin-top: 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.87rem;
  text-align: center;
  justify-content: center;
  ${theme.mediaQueries.mediumScreen} {
    flex: 1;
    direction: rtl;
    grid-template-columns: 14.93rem max-content 1fr;
    text-align: left;
    gap: 3.68rem;
  }
`;

export const SocialNetworkContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  justify-items: center;
  width: 100%;
  max-width: 260px;
  margin-bottom: 56px;
`;

export const TermsAndConditionsContainer = styled.div`
  width: 100%;
  background: #0c1a3c;
  display: flex;
  justify-content: center;
  ${theme.mediaQueries.mediumScreen} {
    justify-content: start;
  }
`;

export const TermsAndConditions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding: 15px 0;
  width: 100%;
  max-width: 390px;
  & > * {
    width: 100%;
    text-align: center;
    border-left: 1px solid ${theme.colors.white};
  }
  & > *:first-child {
    border-left: none;
  }
`;
