import styled, { css } from 'styled-components';

import theme from 'theme';
import { TypesColors } from 'theme/colors';

export type VariantText =
  | 'HELVETICA_16_500'
  | 'HELVETICA_39_700'
  | 'HELVETICA_23_700'
  | 'HELVETICA_16_400'
  | 'HELVETICA_16_700'
  | 'HELVETICA_17_700'
  | 'HELVETICA_18_700'
  | 'HELVETICA_20_700'
  | 'HELVETICA_12_400'
  | 'HELVETICA_14_400'
  | 'ARIAL_25_700'
  | 'LATO_22_400';

export const cssTextVariant = {
  HELVETICA_16_500: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
  `,
  HELVETICA_16_700: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 16px;
    line-height: 13px;
  `,
  HELVETICA_17_700: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 700;
    font-size: 17px;
    line-height: 27px;
  `,
  HELVETICA_18_700: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  `,
  HELVETICA_39_700: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 800;
    font-size: 16px;
    line-height: 14px;
  `,
  HELVETICA_23_700: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 800;
    font-size: 16px;
    line-height: 14px;
  `,
  HELVETICA_16_400: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
  `,
  HELVETICA_20_700: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  HELVETICA_12_400: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  `,
  HELVETICA_14_400: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  `,
  ARIAL_25_700: css`
    font-family: ${theme.fontsFamily.arial};
    font-weight: 700;
    font-size: 25px;
    line-height: 48px;
  `,
  LATO_22_400: css`
    font-family: ${theme.fontsFamily.lato};
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
  `,
};

interface ITextProps {
  $variant: VariantText;
  $color?: TypesColors;
}

export const TextStyle = styled.p<ITextProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  color: ${({ $color }) =>
    $color ? theme.colors[$color] : theme.colors.black};
`;
