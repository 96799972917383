import React from 'react';

// styles
import {
  SectionStyle,
  BGStyle,
  TitleStyle,
  WrapperStyle,
  CardStyle,
} from './style';

interface IProps {
  className?: string;
  optionSelect?: () => void;
}

const Information = ({ className = '' }: IProps) => {
  return (
    <SectionStyle className={`information__home ${className}`}>
      <BGStyle>
        <TitleStyle level={2} variant="HELVETICA_20_700" color="white">
          A secure platform enabling Health data exchange, communication and
          collaboration
        </TitleStyle>
        <WrapperStyle>
          <CardStyle
            image="static/Information/card-01.svg"
            content="Synchronous Telemedicine"
          />

          <CardStyle
            image="static/Information/card-02.svg"
            content="Patient-Centric Collaborative Care"
          />

          <CardStyle
            image="static/Information/card-03.svg"
            content="HIPAA-Compliant Health Data Exchange"
          />

          <CardStyle
            image="static/Information/card-04.svg"
            content=" Asynchronous Telemedicine"
          />

          <CardStyle
            image="static/Information/card-05.svg"
            content="Cloud-Based"
          />

          <CardStyle
            image="static/Information/card-06.svg"
            content="White-Labeled Enterprise-Ready"
          />
        </WrapperStyle>
      </BGStyle>
    </SectionStyle>
  );
};

export default Information;
