import React from 'react';

import { TypesColors } from 'theme/colors';

// styles
import {
  TitleStyle1,
  TitleStyle2,
  TitleStyle3,
  TitleStyle4,
  TitleStyle5,
  TitleStyle6,
  VariantTitle,
} from './style';

interface ITitleProps {
  level: number;
  variant?: VariantTitle;
  color?: TypesColors;
  html?: string;
  children?: React.ReactNode;
  className?: string;
}

const TitleComponent = ({
  level = 3,
  variant = 'HELVETICA_33_700',
  color,
  html,
  className = 'title',
  children,
}: ITitleProps) => {
  if (html) {
    switch (level) {
      case 1:
        return (
          <TitleStyle1
            $variant={variant}
            $color={color}
            className={`${className}`}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </TitleStyle1>
        );
      case 2:
        return (
          <TitleStyle2
            $variant={variant}
            $color={color}
            className={`${className}`}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </TitleStyle2>
        );
      case 3:
        return (
          <TitleStyle3
            $variant={variant}
            $color={color}
            className={`${className}`}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </TitleStyle3>
        );
      case 4:
        return (
          <TitleStyle4
            $variant={variant}
            $color={color}
            className={`${className}`}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </TitleStyle4>
        );
      case 5:
        return (
          <TitleStyle5
            $variant={variant}
            $color={color}
            className={`${className}`}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </TitleStyle5>
        );
      case 6:
        return (
          <TitleStyle6
            $variant={variant}
            $color={color}
            className={`${className}`}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </TitleStyle6>
        );
    }
  }

  switch (level) {
    case 1:
      return (
        <TitleStyle1
          $variant={variant}
          $color={color}
          className={`${className}`}
        >
          {children}
        </TitleStyle1>
      );
    case 2:
      return (
        <TitleStyle2
          $variant={variant}
          $color={color}
          className={`${className}`}
        >
          {children}
        </TitleStyle2>
      );
    case 3:
      return (
        <TitleStyle3
          $variant={variant}
          $color={color}
          className={`${className}`}
        >
          {children}
        </TitleStyle3>
      );
    case 4:
      return (
        <TitleStyle4
          $variant={variant}
          $color={color}
          className={`${className}`}
        >
          {children}
        </TitleStyle4>
      );
    case 5:
      return (
        <TitleStyle5
          $variant={variant}
          $color={color}
          className={`${className}`}
        >
          {children}
        </TitleStyle5>
      );
    case 6:
      return (
        <TitleStyle6
          $variant={variant}
          $color={color}
          className={`${className}`}
        >
          {children}
        </TitleStyle6>
      );
  }

  return (
    <TitleStyle3 $variant={variant} $color={color} className={`${className}`}>
      {children}
    </TitleStyle3>
  );
};

export default TitleComponent;
