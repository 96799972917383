import React from 'react';

import Footer from 'components/organisms/Footer';

interface IWrapper {
  isFooter?: boolean;
  children?: React.ReactNode | JSX.Element;
}

export default function WrapperPagesContent({
  isFooter = false,
  children,
}: IWrapper) {
  if (!isFooter) return <>{children}</>;

  return (
    <>
      {children}
      <Footer />
    </>
  );
}
