import styled from 'styled-components';
import theme from 'theme';

export const Container = styled.section`
  padding: 2.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 57.5rem;
  margin-top: 39.17px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 0.56rem 1.51rem;
  width: 100%;
  ${theme.mediaQueries.mediumScreen} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LeftContent = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 13px;
`;

export const InputSubmit = styled.input`
  width: 291.67px;
  max-width: 100%;
  border-radius: 10.8333px;
  height: 36.67px;
  margin-top: 36.67px;
  background: ${theme.colors.green};
  font-family: ${theme.fontsFamily.helvetica};
  font-weight: 700;
  font-size: 20px;
  line-height: 17px;
  color: ${theme.colors.white};
  cursor: pointer;
`;
