import React from 'react';

import { WrapperStyleCardInformative, ImageStyle, TextStyle } from './style';

interface ICard {
  className?: string;
  content?: string;
  image?: string;
}

const CardInformative = ({
  className = '',
  image = '',
  content = '',
}: ICard) => {
  return (
    <WrapperStyleCardInformative className={`informative__card ${className}`}>
      <ImageStyle src={image} />
      <TextStyle color="gray" variant="HELVETICA_23_700">
        {content}
      </TextStyle>
    </WrapperStyleCardInformative>
  );
};

export default CardInformative;
