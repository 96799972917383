import React from 'react';

import useModal from 'shared/utils/hooks/useModal';
import useTopState from 'shared/utils/hooks/useTopState';

import ItemMenu from 'components/molecules/ItemMenu';

// styles
import {
  StyleBrand,
  NavStyle,
  ListStyle,
  ListStyleMobile,
  HamburguerStyle,
  WrapperMobileMenu,
  WrapperMobileMenuFixed,
} from './style';

interface ITextProps {
  className?: string;
  optionSelect?: () => void;
}

const Navbar = ({ className = '' }: ITextProps) => {
  const isTop: boolean = useTopState();
  const { isOpen, toggle } = useModal();

  return (
    <>
      <NavStyle className={`menu ${className}`}>
        <StyleBrand>
          <a href="/">
            <img src="/static/logo_white.svg" alt="" />
          </a>
        </StyleBrand>

        <ListStyle className={`menu__list`}>
          <ItemMenu redirect="/about">About</ItemMenu>
          <ItemMenu redirect="/services">Services</ItemMenu>
          <ItemMenu redirect="#contact-us">Contact</ItemMenu>
        </ListStyle>

        <HamburguerStyle
          className="p-4 space-y-2 rounded border border-zinc-50 menu__list--mobile"
          onClick={toggle}
        >
          <div className="w-8 h-0.5 bg-zinc-50"></div>
          <div className="w-8 h-0.5 bg-zinc-50"></div>
          <div className="w-8 h-0.5 bg-zinc-50"></div>
        </HamburguerStyle>
      </NavStyle>

      {isOpen && !isTop && (
        <WrapperMobileMenuFixed>
          <ListStyleMobile>
            <ItemMenu redirect="/">Home</ItemMenu>
            <ItemMenu redirect="/about">About</ItemMenu>
            <ItemMenu redirect="/services">Services</ItemMenu>
            <ItemMenu redirect="#contact-us">Contact</ItemMenu>
          </ListStyleMobile>
        </WrapperMobileMenuFixed>
      )}

      {isOpen && isTop && (
        <WrapperMobileMenu>
          <ListStyleMobile>
            <ItemMenu redirect="/">Home</ItemMenu>
            <ItemMenu redirect="/about">About</ItemMenu>
            <ItemMenu redirect="/services">Services</ItemMenu>
            <ItemMenu redirect="#contact-us">Contact</ItemMenu>
          </ListStyleMobile>
        </WrapperMobileMenu>
      )}
    </>
  );
};

export default Navbar;
