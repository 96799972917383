import React, { useMemo } from 'react';
import LottieModule, { LottieProps, Options } from 'react-lottie';
import FailAnimation from './animations/Failed-Red.json';
import CheckAnimation from './animations/check-green.json';

interface OptionsProps extends Options {
  animationData: 'fail' | 'check';
}

interface Props extends LottieProps {
  options: OptionsProps;
}

const Animations = {
  fail: FailAnimation,
  check: CheckAnimation,
};

const Lottie = (lottie: Props) => {
  const options = useMemo(
    () => ({
      ...lottie.options,
      animationData: Animations[lottie.options.animationData],
    }),
    [lottie.options]
  );

  return <LottieModule {...lottie} options={options} />;
};

export default Lottie;
