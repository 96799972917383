import styled, { css } from 'styled-components';

import theme from 'theme';
import { TypesColors } from 'theme/colors';

export type VariantTitle =
  | 'HELVETICA_45_700'
  | 'HELVETICA_45_400'
  | 'HELVETICA_19_700'
  | 'HELVETICA_33_700'
  | 'HELVETICA_20_700'
  | 'HELVETICA_26_400';

export const cssTextVariant = {
  HELVETICA_45_700: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 45px;
    line-height: 51px;
  `,
  HELVETICA_45_400: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 400;
    font-size: 45px;
    line-height: 48px;
  `,
  HELVETICA_39_700: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 700;
    font-size: 39px;
    line-height: 59px;
  `,
  HELVETICA_19_700: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 19px;
    line-height: 48px;
  `,
  HELVETICA_33_700: css`
    font-family: ${theme.fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 33px;
    line-height: 48px;
  `,
  HELVETICA_20_700: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  HELVETICA_26_400: css`
    font-family: ${theme.fontsFamily.helvetica};
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
  `,
};

interface ITitleProps {
  $variant: VariantTitle;
  $color?: TypesColors;
}

export const TitleStyle1 = styled.h1<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  color: ${({ $color }) =>
    $color ? theme.colors[$color] : theme.colors.black};
`;

export const TitleStyle2 = styled.h2<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  color: ${({ $color }) =>
    $color ? theme.colors[$color] : theme.colors.black};
`;

export const TitleStyle3 = styled.h3<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  color: ${({ $color }) =>
    $color ? theme.colors[$color] : theme.colors.black};
`;

export const TitleStyle4 = styled.h4<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  color: ${({ $color }) =>
    $color ? theme.colors[$color] : theme.colors.black};
`;

export const TitleStyle5 = styled.h5<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  color: ${({ $color }) =>
    $color ? theme.colors[$color] : theme.colors.black};
`;

export const TitleStyle6 = styled.h6<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  color: ${({ $color }) =>
    $color ? theme.colors[$color] : theme.colors.black};
`;
