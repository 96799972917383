import styled from 'styled-components';

import theme from 'theme';

import Text from 'components/atoms/Text';

const { colors, mediaQueries } = theme;

export const WrapperStyleCardInformative = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5vh 2.5vw;
  background-color: ${colors.white};
  border-radius: 16px;
  box-shadow: 0 0 9px ${colors.whiteOpacity(0.15)};

  ${mediaQueries.xLargeScreen} {
    padding-bottom: 8vh;
  }
`;

export const ImageStyle = styled.img`
  height: 50px;
  object-fit: contain;
  margin-top: 25%;

  ${mediaQueries.size350Font} {
    margin-top: 0;
  }

  ${mediaQueries.xxSmallScreenToMax} {
    height: 18vw;
    margin-top: 22%;
  }

  ${mediaQueries.xSmallScreen} {
    height: 17vw;
    margin-top: 15%;
  }

  ${mediaQueries.screenTo660} {
    margin-top: 24%;
  }

  ${mediaQueries.smallScreen} {
    height: 120px;
  }

  ${mediaQueries.screenTo920} {
    margin-top: 31%;
    height: 130px;
  }

  ${mediaQueries.mediumScreen} {
    margin-top: 31%;
    height: 135px;
  }

  ${mediaQueries.largeScreen} {
    margin-top: 22%;
    height: 115px;
  }

  ${mediaQueries.screenTo1800} {
    width: 100%;
    height: 25%;
  }
`;

export const TextStyle = styled(Text)`
  margin-top: 10vw;
  text-align: center;
  line-height: 7vw;
  font-size: 19px;

  ${mediaQueries.size350Font} {
    font-size: 14px;
  }

  ${mediaQueries.xxSmallScreenToMax} {
    margin-top: 5vw;
  }

  ${mediaQueries.xSmallScreen} {
    margin-top: 40px;
    line-height: 35px;
    font-size: 27px;
  }

  ${mediaQueries.screenTo660} {
    margin-top: 75px;
    font-size: 29px;
  }

  ${mediaQueries.smallScreen} {
    margin-top: 55px;
    line-height: 45px;
    font-size: 36px;
  }

  ${mediaQueries.screenTo920} {
    margin-top: 80px;
    line-height: 59px;
    font-size: 47px;
  }

  ${mediaQueries.mediumScreen} {
    line-height: 46.9px;
    font-size: 32px;
  }

  ${mediaQueries.largeScreen} {
    line-height: 34.9px;
    font-size: 24px;
    margin-top: 75px;
  }

  ${mediaQueries.xLargeScreen} {
    line-height: 49.9px;
    font-size: 28px;
    margin-top: 65px;
  }

  ${mediaQueries.screenTo1800} {
    line-height: 49.9px;
    font-size: 39px;
    margin-top: 130px;
  }
`;
