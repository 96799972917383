import Text from 'components/atoms/Text';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  path?: string;
}

const FooterSectionItem = ({ title, path }: Props) => {
  if (path) {
    return (
      <Link to={path}>
        <Text variant="HELVETICA_16_500" color="white">
          {title}
        </Text>
      </Link>
    );
  }
  return (
    <Text variant="HELVETICA_17_700" color="white">
      {title}
    </Text>
  );
};

export default FooterSectionItem;
