import React from 'react';
import Logo from 'components/atoms/Logo';
import FooterSection from 'components/molecules/FooterSection';
import Text from 'components/atoms/Text';
import {
  FooterStyled,
  Grid,
  Information,
  SocialNetworkContainer,
  InformationContainer,
  TermsAndConditions,
  TermsAndConditionsContainer,
  BlurContainer,
  NoBlurContainer,
} from './style';

const Footer = () => (
  <FooterStyled className="footer">
    <InformationContainer>
      <BlurContainer />
      <NoBlurContainer>
        <Grid>
          <FooterSection
            title="Contact"
            items={[
              {
                label: '8565 S. Eastern Ave., Ste. 170 Las Vegas, NV 89123',
              },
              {
                label: 'Tel: +1 (702) 867-0068',
              },
              {
                label: 'Email: Info@noyaview.com',
              },
            ]}
          />
          <FooterSection
            title="Services"
            items={[
              {
                label: 'For Providers',
              },
              {
                label: 'Health Plans',
              },
              {
                label: 'Patients',
              },
            ]}
          />
        </Grid>
        <Information>
          <Logo />
          <SocialNetworkContainer>
            <a href="/">
              <img src="/static/Facebook.png" alt="Facebook Icon" />
            </a>
            <a href="/">
              <img src="/static/Twitter.png" alt="Twitter Icon" />
            </a>
            <a href="/">
              <img src="/static/LinkeIn.png" alt="LinkeIn Icon" />
            </a>
            <a href="/">
              <img src="/static/Instagram.png" alt="Instagram Icon" />
            </a>
          </SocialNetworkContainer>
          <Text variant="HELVETICA_12_400" color="white">
            © 2022 NoyaVentures, Inc. All Rights Reserved
          </Text>
        </Information>
      </NoBlurContainer>
    </InformationContainer>
    <TermsAndConditionsContainer>
      <TermsAndConditions>
        <Text variant="HELVETICA_14_400" color="white">
          Terms of usage
        </Text>
        <Text variant="HELVETICA_14_400" color="white">
          Privacy Policy
        </Text>
        <Text variant="HELVETICA_14_400" color="white">
          Sitemap
        </Text>
      </TermsAndConditions>
    </TermsAndConditionsContainer>
  </FooterStyled>
);

export default Footer;
