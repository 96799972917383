import styled from 'styled-components';

import theme from 'theme';

import Text from 'components/atoms/Text';

export const TextStyle = styled(Text)``;

export const ItemStyle = styled.li`
  cursor: pointer;

  p {
    padding: 10px 0;
  }

  &:hover {
    p {
      border-bottom: solid 1px ${theme.colors.green};
    }
  }
`;

export const AncleStyle = styled.a``;
