export const fonts = `
  @font-face {
    font-family: 'Helvetica';
    src: url('/fonts/Helvetica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica-bold';
    src: url('/fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica-bold';
    src: url('/fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica-bold';
    src: url('/fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica-bold';
    src: url('/fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica-bold';
    src: url('/fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica-bold';
    src: url('/fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: bolder;
    font-style: normal;
  }


  @font-face {
    font-family: 'Lato';
    src: url('/fonts/lato/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
`;

export const fallbackFonts = 'sans-serif';

export const fontsFamily = {
  helvetica: `'Helvetica'`,
  helveticaBold: `'Helvetica-bold'`,
  arial: `'Arial'`,
  lato: `'lato'`,
};

export type TypesFonts = 'helvetica' | 'helveticaBold';

export default fontsFamily;
