import React from 'react';

import { TypesColors } from 'theme/colors';

// styles
import { TextStyle, VariantText } from './style';

interface ITextProps {
  color?: TypesColors;
  variant?: VariantText;
  html?: string;
  children?: React.ReactNode;
  className?: string;
}

const Text = ({
  children,
  color,
  variant = 'HELVETICA_16_500',
  html,
  className = '',
}: ITextProps) => {
  if (html) {
    return (
      <TextStyle
        $variant={variant}
        $color={color}
        className={`typography ${className}`}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </TextStyle>
    );
  }

  return (
    <TextStyle
      $variant={variant}
      $color={color}
      className={`typography ${className}`}
    >
      {children}
    </TextStyle>
  );
};

export default Text;
