import React from 'react';

import { MainComponent } from './navigation';

import './App.css';
import GlobalStyle from './theme/general';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <>
      <GlobalStyle />
      <MainComponent />
    </>
  );
}

export default App;
