import React from 'react';

// styles
import { ButtonStyle, IBorderType, VariantButton } from './style';

interface IButtonProps {
  children?: React.ReactNode;
  variant: VariantButton;
  borderRadius?: IBorderType;
  className?: string;
}

const Button = ({
  children,
  variant,
  borderRadius = 'default',
  className = '',
}: IButtonProps) => {
  return (
    <ButtonStyle
      className={`button ${className}`}
      $variant={variant}
      $isBorder={borderRadius}
    >
      {children}
    </ButtonStyle>
  );
};

export default Button;
