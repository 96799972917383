import React from 'react';

// styles
import {
  HeaderStyle,
  BGStyle,
  WrapperStyle,
  TitleStyle,
  ButtonHeaderStyle,
  TextButtonStyle,
  LoginRedirectStyle,
} from './style';

interface ITextProps {
  className?: string;
  optionSelect?: () => void;
}

const HeaderHome = ({ className = '' }: ITextProps) => {
  return (
    <HeaderStyle className={`header__home ${className}`}>
      <BGStyle>
        <WrapperStyle>
          <TitleStyle level={1} variant="HELVETICA_45_400" color="white">
            A Global <b>HIPAA-Compliant Communication</b> and Patient-Centric
            <b> Collaborative Care</b> Platform
          </TitleStyle>
        </WrapperStyle>

        <LoginRedirectStyle href="https://testingapp.noyaview.com/login/">
          <ButtonHeaderStyle variant="FULL_GREEN">
            <TextButtonStyle color="white" variant="HELVETICA_20_700">
              Login
            </TextButtonStyle>
          </ButtonHeaderStyle>
        </LoginRedirectStyle>
      </BGStyle>
    </HeaderStyle>
  );
};

export default HeaderHome;
